@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .shadow-neomorphic {
    box-shadow: 8px 8px 16px #0A0D0E, -8px -8px 16px #161C1F;
  }

  .shadow-neomorphic-inset {
    box-shadow: inset 8px 8px 16px #0A0D0E, inset -8px -8px 16px #161C1F;
  }
}

body {
  background-color: #161616;
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}